import { AvatarBlurhash, getSize } from './AvatarBlurhash'
import { useCompany, getInitialism } from '@front/model'
import { Group, Skeleton, Text, Title } from '@mantine/core'
import { Link } from '@remix-run/react'
import type { CompanyId } from '@terros/common'
import type { ReactElement, ReactNode } from 'react'

type CompanyInfoFetcherInput = {
  size?: 'sm' | 'md'
  avatarOnly?: boolean
  companyId: CompanyId
  linked?: boolean
}

type CompanyInfoPrefetchedInput = CompanyInfoFetcherInput & {
  avatarUrl?: string
  avatarBlurhash?: string
  initials: string
  name: string
}

export const CompanyInfo = ({ size = 'sm', avatarOnly, companyId, linked }: CompanyInfoFetcherInput): ReactNode => {
  const { data, isLoading, error } = useCompany({ companyId })
  if (error) return <Text className='text-red-400'>{error}</Text>
  if (isLoading || !data)
    return avatarOnly ? <Skeleton circle h={getSize(size)} w={getSize(size)} /> : <Skeleton h={20} w={200} />
  return (
    <PrefetchedCompanyInfo
      avatarBlurhash={data.avatarBlurhash}
      avatarOnly={avatarOnly}
      avatarUrl={data.avatarUrl}
      companyId={companyId}
      initials={getInitialism(data.name)}
      linked={linked}
      name={data.name}
      size={size}
    />
  )
}

export const PrefetchedCompanyInfo = (props: CompanyInfoPrefetchedInput): ReactElement => {
  const { size = 'sm', avatarUrl, avatarBlurhash, initials, name, avatarOnly, companyId, linked = true } = props
  if (linked) {
    return (
      <Link to={`/companies/${companyId}`}>
        <PrefetchedCompanyInfo {...props} linked={false} />
      </Link>
    )
  }
  return (
    <Group wrap='nowrap'>
      <AvatarBlurhash avatarBlurhash={avatarBlurhash} avatarUrl={avatarUrl} initials={initials} size={size} />
      {!avatarOnly &&
        (size === 'md' ? (
          <Title lineClamp={1} order={3}>
            {name}
          </Title>
        ) : (
          <Text lineClamp={1}>{name}</Text>
        ))}
    </Group>
  )
}
